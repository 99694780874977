import React, { Component } from 'react';
import 'intl';

import SocialLinks from './socialLinks';
import ClubsSlider from './clubsSlider.js';
import Img from 'gatsby-image';
import Link from 'gatsby-link';
import { Button, Col, Container, Row } from 'reactstrap';

import '../assets/scss/pages/_AnalysisPage.scss';

import a1 from '../assets/img/analysis-image-1.png';
import a2 from '../assets/img/analysis-image-2.png';
import a3 from '../assets/img/analysis-image-3.png';
import a4 from '../assets/img/analysis-image-4.png';
import a5 from '../assets/img/analysis-image-5.png';
import a6 from '../assets/img/analysis-image-6.png';
import a7 from '../assets/img/analysis-image-7.png';
import pScoutingW from '../assets/img/p-scouting-w.png';
import scoutingSBg from '../assets/img/scouting-s-bg.png';
import { FormattedMessage } from 'react-intl';


class AnalysisPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { headerImage, headerImageMobile, clubsItems, slidesItems, intro, product, conceptBehind, workingWithPlatform, productScouting } = this.props;
    return (
      <div className="analysis-wrapper">

        <section className="intro-wrapper text-center">
          <div className="intro">
            <div
              className="position-absolute social-links-wrapper"
            >
              <SocialLinks/>
            </div>
            <Img
              className='img-fluid d-none d-md-block'
              style={{ position: 'initial' }}
              fixed={headerImage}
            />
            <Img
              className='img-fluid d-block d-md-none'
              style={{ position: 'initial' }}
              fluid={headerImageMobile}
            />
          </div>

          <Container>
            <Row>
              <Col md={{ offset: 2, size: 8 }}>
                <p className="text-center mt-4 mb-4">
                  {intro.subHeader || null}
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section
          className="text-image-section s-1 bg-w"
        >
          <Container className="">
            <Row>
              <img
                src={a1}
                alt="d"
                className="image-r ai-1"
              />
              <div className="text-l">
                <Row>
                  <Col md={{ size: 6 }}>
                    <h2>
                      <span>{product.preHeader || null}</span>
                      {product.header || null}
                    </h2>
                  </Col>
                </Row>

                <Row className="">
                  <Col md={{ size: 5, offset: 1 }}>
                    {product.contentOne || null}
                  </Col>
                </Row>
              </div>
            </Row>

            <Row className="w-2">
              <img
                src={a2}
                alt="d"
                className="image-l ai-2"
              />
              <div className="text-r">
                <Row>
                  <Col md={{ size: 5, offset: 7 }}>
                    {product.contentTwo || null}
                  </Col>
                </Row>
              </div>
            </Row>
          </Container>
        </section>
        <div className="oblique-block"/>

        <section
          className="text-image-section s-2 bg-bg"
        >
          <Container className="">
            {/*<Row className="slider">
             <Col>
             <Slider items={slidesItems} />
             </Col>
             </Row>*/}

            <Row>
              <img
                src={a3}
                alt="d"
                className="image-r ai-3"
              />
              <div className="text-l">
                <Row>
                  <Col md={{ size: 6 }}>
                    <h2>
                      <span>Impect</span>
                      {conceptBehind.header || null}
                    </h2>
                  </Col>
                </Row>

                <Row className="">
                  <Col md={{ size: 5, offset: 1 }}>
                    <p>{conceptBehind.contentOne || null}</p>
                  </Col>
                </Row>
              </div>
            </Row>

            <Row className="w-4">
              <img
                src={a4}
                alt="d"
                className="image-l ai-4"
              />
              <div className="text-r">
                <Row>
                  <Col md={{ size: 5, offset: 7 }}>
                    <p>{conceptBehind.contentTwo || null}</p>

                    <Button>
                      <span className="text-g">
                        <FormattedMessage id="learnMore"/>
                      </span>
                    </Button>
                  </Col>
                </Row>
              </div>
            </Row>
          </Container>
        </section>
        <div className="oblique-block"/>

        <section
          className="text-image-section s-3 bg-w"
        >
          <Container className="">
            <Row style={{ marginBottom: 100 }}>
              <Col>
                <ClubsSlider items={clubsItems}/>
              </Col>
            </Row>

            <Row>
              <img
                src={a5}
                alt="d"
                className="image-r ai-5"
              />
              <div className="text-l">
                <Row>
                  <Col md={{ size: 6 }}>
                    <h2>
                      <span>packing</span>
                      {workingWithPlatform.header || null}
                    </h2>
                  </Col>
                </Row>

                <Row className="">
                  <Col md={{ size: 5, offset: 1 }}>
                    <p>{workingWithPlatform.contentOne || null}</p>
                  </Col>
                </Row>
              </div>
            </Row>

            <Row className="w-6">
              <img
                src={a6}
                alt="d"
                className="image-l ai-6"
              />
              <div className="text-r">
                <Row>
                  <Col md={{ size: 5, offset: 7 }}>
                    <p>{workingWithPlatform.contentTwo || null}</p>
                  </Col>
                </Row>
              </div>
            </Row>

            <Row className="">
              <img
                src={a7}
                alt="d"
                className="image-r ai-7"
              />
              <div className="text-l">
                <Row>
                  <Col md={{ size: 6 }}>
                    <p>{workingWithPlatform.contentThree || null}</p>
                  </Col>
                </Row>
              </div>
            </Row>
          </Container>
        </section>
        <div className="oblique-block"/>

        <section
          className="text-image-section s-4 bg-g"
        >
          {/*<ProfessionalsSay
           items={this.props.professionalsSay}
           />*/}
        </section>

        <section
          className="text-image-section oblique s-6 bg-s-g"
        >
          <Container className="oblique-content">
            <Row>
              <Col md={{ size: 5 }}>
                <img className="mb-4" src={pScoutingW}/>
                <p className="mb-4">
                  {productScouting.content || null}
                </p>
                <Button>
                  <Link to={`${this.props.activeLangKey}/products/scouting`} className="text-g">
                    <FormattedMessage id="learnMore"/>
                  </Link>
                </Button>
              </Col>
            </Row>
          </Container>
          <img
            src={scoutingSBg}
            alt="d"
            className="bg"
          />
        </section>

      </div>
    );
  }
}

export default AnalysisPage;
